.App {
  text-align: center;
  justify-content: center;
  display: flex;
}

.content {
  width: 100%;
  gap: 5vw;
  display: flex;
  justify-content: center;
}

.hehe, .xd {
  display: inline-block;
  font-size: 500%;
}

.hehe {
  color: #282c34;
}

.xd {
  color: #2c0d73;
}

.hehe:hover, .xd:hover {
  cursor: default;
  filter: invert(40%);
}

.App-header {
  background-color: #0e1010;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
